const React = require('react');

const metadataPropType = require('../../components/metadataProvider/metadataPropType');

const withShopDomain = (Base) => {
  const ComponentBase = (props, { metadata }) => {
    const { shopModel: { shop, storefront } } = metadata || {};
    const domain = shop?.domain || storefront?.domain;
    return (
      <Base
        domain={domain}
        {...props}
      />
    );
  };

  ComponentBase.contextTypes = {
    metadata: metadataPropType.isRequired,
  };

  return ComponentBase;
};

module.exports = withShopDomain;
