const React = require('react');

const LabelImage = require('../../components/labelImage');

const metadataPropType = require('../../components/metadataProvider/metadataPropType');

const LabelImageWrapper = (props, context) => {
  const { metadata: { layoutTheme } } = context;
  return (
    <LabelImage
      {...props}
      layoutTheme={layoutTheme}
    />
  );
};

LabelImageWrapper.contextTypes = {
  metadata: metadataPropType.isRequired,
};

module.exports = LabelImageWrapper;
