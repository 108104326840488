module.exports = {
  AFIP: 'AFIP',
  BANNER: 'BANNER',
  CAROUSEL: 'CAROUSEL',
  CAROUSEL_SPLINTER: { brands: 'BRANDS', categories: 'CATEGORIES' },
  COLLECTION_GRID: 'GRID',
  CONTACT: 'CONTACT',
  CONTENT_LIST: 'CONTENT_LIST',
  DISCOVERY: 'DISCOVERY',
  FLASH: 'FLASH',
  FOOTER: 'FOOTER',
  HEADER: 'HEADER',
  HEADER_MESSAGE: 'HEADER_MESSAGE',
  LOGO: 'LOGO',
  MOSAIC: 'MOSAIC',
  PAYMENT_DATA: 'PAYMENT_DATA',
  SOCIAL_NETWORKS: 'SOCIAL_NETWORKS',
  SLIDER: 'SLIDER',
  SPLINTER_BANNER: 'TEXT_BANNER',
  PRICE_AND_IMAGE: 'PRICE_AND_IMAGE',
  TABBED_CAROUSEL: 'TABBED_CAROUSEL',
  WHATSAPP: 'WHATSAPP',
  LAYOUT_ORDER: 'LAYOUT_ORDER',
  LAYOUT_UPDATE: 'LAYOUT_UPDATE',
  SECTION_TEXT: 'SECTION_TEXT',
  VIDEO_AND_TEXT: 'VIDEO_AND_TEXT',
  LAYOUT_REMOVE_COMPONENT: 'LAYOUT_REMOVE_COMPONENT',
  LAYOUT_ADD_COMPONENT: 'LAYOUT_ADD_COMPONENT',
  EMPTY_CONFIG_COMPONENT: 'EMPTY_CONFIG_COMPONENT',
  // Editor request home to open a panel for a specified component
  CHANGE_SCOPE: 'CHANGE_SCOPE',
  CATEGORIES_GALLERY: 'CATEGORIES_GALLERY',
  CATEGORY_CAROUSEL_V2: 'CATEGORY_CAROUSEL_V2',
  COLLECTION_BOXES: 'COLLECTION_BOXES',
  PROFILE_HEADER: 'PROFILE_HEADER',
  VALUE_PROP: 'VALUE_PROP',
  MERCH_SLIDER: 'MERCH_SLIDER',
};
