const { TYPES } = require('../../../utils/constants/shop/types');

const WITH_MOBILE_VARIATIONS = [
  TYPES.SELLER,
];

const getMaxItems = (shopType, device, maxItems, maxItemsMobile) => {
  if (WITH_MOBILE_VARIATIONS.includes(shopType) && maxItemsMobile && device === 'mobile') {
    return maxItemsMobile;
  }

  return maxItems;
};

const getItemsPerRow = (shopType, device, itemsPerRow, itemsPerRowMobile) => {
  if (WITH_MOBILE_VARIATIONS.includes(shopType) && itemsPerRowMobile && device === 'mobile') {
    return itemsPerRowMobile;
  }

  return itemsPerRow;
};

module.exports = {
  WITH_MOBILE_VARIATIONS,
  getMaxItems,
  getItemsPerRow,
};
