const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

const Image = require('nordic/image');

const Action = require('../action');
const metadataPropType = require('../metadataProvider/metadataPropType');
const Header = require('./header');

const CollectionBox = (props) => {
  const { styles, content, i18n } = props;

  const {
    behavior,
    label: titleCollection,
    secondLabel: subtitleCollection,
    anchored,
    link: {
      url: linkCollection,
    },
  } = content;

  const {
    background_image: urlImage,
    is_default_image: isDefaultImage,
  } = styles;

  const imageContainerClass = classNames('collection-box-item__image-container',
    {
      'collection-box-item__image-default': isDefaultImage,
    });

  const anchoredContainer = anchored && (
    <div className="collection-box-item__tag">
      {i18n.gettext('Anclada')}
    </div>
  );

  const behaviorClassName = classNames(
    'collection-box-item__header',
    {
      'collection-box-item__title--hidden': !titleCollection,
      'collection-box-item__subtitle--hidden': !subtitleCollection,
    },
  );

  const itemClass = classNames('collection-box-item',
    {
      [`collection-box-item--${behavior}`]: behavior,
    });

  return (
    <Action className={itemClass} target={linkCollection}>
      <div className="collection-box-item__container">
        <div className={imageContainerClass}>
          <Image
            className="collection-box-item__image"
            src={urlImage}
            onload="on"
            alt={titleCollection}
          />
        </div>
        <div className={behaviorClassName}>
          <Header
            title={titleCollection}
            subtitle={subtitleCollection}
          />
        </div>
        {anchoredContainer}
      </div>
    </Action>
  );
};

CollectionBox.propTypes = {
  i18n: PropTypes.shape(),
  styles: PropTypes.shape({
    background_image: PropTypes.string,
    is_default_image: PropTypes.bool,
  }).isRequired,
  content: PropTypes.shape({
    link: PropTypes.shape({
      url: PropTypes.string,
    }).isRequired,
    label: PropTypes.string,
    secondLabel: PropTypes.string,
    behavior: PropTypes.oneOf(['show', 'hide', 'hover']),
    anchored: PropTypes.bool.isRequired,
  }).isRequired,
};

CollectionBox.defaultProps = {
  i18n: { gettext: f => f },
};

CollectionBox.contextTypes = {
  metadata: metadataPropType.isRequired,
};

module.exports = CollectionBox;
