const React = require('react');
const PropTypes = require('prop-types');

function Document({ children }) {
  return (
    <div>
      {children}
    </div>
  );
}

Document.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

module.exports = Document;
