const React = require('react');
const PropTypes = require('prop-types');
const Classnames = require('classnames');
const uuid = require('uuid/v4');

const metadataPropType = require('../../components/metadataProvider/metadataPropType');
const { THEME_TITLE_FONT } = require('./utils');

const CollectionBoxes = ({
  title,
  children,
  editable,
  fakeChildren,
  boxes,
}, context) => {
  const { metadata: { layoutTheme } } = context;
  const isMinBoxes = boxes?.length < 2;
  const columnClasses = Classnames(
    'collection-box__columns',
    'collection-box__columns__item',
    {
      [`collection-box__columns--${boxes.length}item`]: !isMinBoxes,
      'collection-box__columns--minItem': isMinBoxes,
    },
  );

  const titleClasses = Classnames(
    'collection-box__title',
    {
      'font-primary-text': layoutTheme !== THEME_TITLE_FONT,
      'font-secondary-text': layoutTheme === THEME_TITLE_FONT,
    },
  );

  return (
    <section className="collection-box">
      <div className="row container collection-box__container">
        {
          title?.text && !title?.hidden && (
            <h2 className={titleClasses}>{title.text}</h2>
          )
        }
        <div className={columnClasses}>
          {
            children?.map(item => (
              <React.Fragment key={uuid()}>{item}</React.Fragment>
            ))
          }
          {
            editable && fakeChildren && fakeChildren()
          }
        </div>
      </div>
    </section>
  );
};

CollectionBoxes.propTypes = {
  title: PropTypes.shape({
    text: PropTypes.string.isRequired,
    hidden: PropTypes.bool.isRequired,
  }).isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]).isRequired,
  editable: PropTypes.bool,
  fakeChildren: PropTypes.func,
  boxes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      source: PropTypes.string.isRequired,
      image_id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ),
};

CollectionBoxes.defaultProps = {
  editable: false,
  fakeChildren: () => {},
  boxes: [],
};

CollectionBoxes.contextTypes = {
  metadata: metadataPropType.isRequired,
};

module.exports = CollectionBoxes;
