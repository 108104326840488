const ClientService = require('../../../../services/client');

const types = require('./types');

const { LAYOUT_UPDATE_MODES } = require('../../../appearance/Layout/layoutActions');

module.exports = class RenderActions {
  constructor(dispatcher, state) {
    this.dispatcher = dispatcher;
    this.restClient = new ClientService(state.apiBasePath);
  }

  static initialize(dispatcher, state) {
    if (!this.singleton) {
      this.singleton = new RenderActions(dispatcher, state);
    }

    return this.singleton;
  }

  changeDevice(device, { sender } = {}) {
    this.dispatcher({
      type: types.CHANGE_DEVICE,
      payload: { device },
      sender,
    });
  }

  changeOsName(osName, { sender } = {}) {
    this.dispatcher({
      type: types.CHANGE_OSNAME,
      payload: { osName },
      sender,
    });
  }

  reorderLayoutComponent(componentId, position, { sender } = {}) {
    this.dispatcher({
      type: types.REORDER_LAYOUT_COMPONENT,
      payload: { componentId, position },
      sender,
    });
  }

  moveComponentUp(component) {
    this.dispatcher({
      type: types.MOVE_COMPONENT_UP,
      payload: { component },
    });
  }

  moveComponentDown(component) {
    this.dispatcher({
      type: types.MOVE_COMPONENT_DOWN,
      payload: { component },
    });
  }

  moveToComponent(componentId) {
    this.dispatcher({
      type: types.MOVE_TO_COMPONENT,
      payload: { componentId },
    });
  }

  resetLayout({ sender } = {}) {
    this.dispatcher({
      type: types.RESET_LAYOUT,
      sender,
    });
  }

  saveLayout({ sender } = {}) {
    this.dispatcher({
      type: types.SAVE_LAYOUT,
      sender,
    });
  }

  changeEditableMode(mode, { sender } = {}) {
    this.dispatcher({
      type: types.CHANGE_EDITABLE_MODE,
      payload: { mode },
      sender,
    });
  }

  updateLayout(layout, { sender } = {}) {
    this.dispatcher({
      type: types.UPDATE_LAYOUT,
      payload: { layout },
      sender,
    });
  }

  triggerAnimationDown(component) {
    this.dispatcher({
      type: types.TRIGGER_ANIMATION_DOWN,
      payload: { component },
    });
  }

  triggerAnimationUp(component) {
    this.dispatcher({
      type: types.TRIGGER_ANIMATION_UP,
      payload: { component },
    });
  }

  updateComponentProps(component, props, { sender } = {}) {
    this.dispatcher({
      type: types.UPDATE_COMPONENT_PROPS,
      payload: { component, props },
      sender,
    });
  }

  removeLayoutComponent(component, { sender } = {}) {
    this.dispatcher({
      type: types.REMOVE_LAYOUT_COMPONENT,
      payload: { component },
      sender,
    });
  }

  setComponentHidden(component, hidden, { sender } = {}) {
    this.dispatcher({
      type: types.SET_COMPONENT_HIDDEN,
      payload: { component, hidden },
      sender,
    });
  }

  addComponent({ component, position }, { sender } = {}) {
    this.dispatcher({
      type: types.ADD_LAYOUT_COMPONENT,
      payload: { componentPayload: component, position },
      sender,
    });
  }

  setSelectComponent(component) {
    this.dispatcher({
      type: types.SECTION_EDITING,
      payload: { component },
    });
  }

  getLayoutFromServer(type, section, device, componentId, uuidVersion) {
    this.dispatcher({
      type: types.LAYOUT_REQUEST,
    });

    return this.restClient.get('/layout', { params: { section, device, uuidVersion } })
      .then(({ data: { appearance } }) => {
        this.dispatcher({
          type: (type === LAYOUT_UPDATE_MODES.FULL)
            ? types.LAYOUT_REQUEST_SUCCESS_FULL
            : types.LAYOUT_REQUEST_SUCCESS_PARTIAL,
          payload: { appearanceResponse: appearance, section, device, componentId },
        });
      })
      .catch(error => {
        this.dispatcher({
          type: types.LAYOUT_REQUEST_ERROR,
          payload: { error },
        });
      });
  }

  setLoading(value) {
    this.dispatcher({
      type: types.SET_LOADING,
      payload: { value },
    });
  }

  setShouldUpdateDevice(updateDevice) {
    this.dispatcher({
      type: types.SHOULD_UPDATE_DEVICE,
      payload: updateDevice,
    });
  }
};
