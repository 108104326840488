const React = require('react');

const SplinterCard = require('@mshops-components-library/splinter-card').default;

const { useRenderContext } = require('../../pages/home/context');

const CardWrapper = (props) => {
  const { device } = useRenderContext();

  return <SplinterCard device={device} {...props} />;
};

module.exports = CardWrapper;
