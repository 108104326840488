const tracks = {
  newsletterView: {
    melidata_event: {
      type: 'view',
      path: '/visitor_registration/form',
      event_data: {},
    },
    analytics_event: {
      path: '/VISITOR_REGISTRATION/FORM/',
      type: 'view',
      page: '/VISITOR_REGISTRATION/',
    },
  },
  newsletterCongratsView: {
    melidata_event: {
      type: 'view',
      path: '/visitor_registration/congrats',
      event_data: {},
    },
    analytics_event: {
      path: '/VISITOR_REGISTRATION/CONGRATS/',
      type: 'view',
      page: '/VISITOR_REGISTRATION/',
    },
  },
  newsletterSubscription: {
    melidata_event: {
      path: '/visitor_registration/form/subscribe_registration',
      event_data: {
        event: {
          event_category: 'automatization',
          event_action: 'subscribe_registration',
          event_label: 'visitor_registration',
        },
      },
    },
    analytics_event: {
      page: '/VISITOR_REGISTRATION/FORM/',
      action: 'SUBSCRIBE_REGISTRATION',
      category: 'AUTOMATIZATION',
      label: 'VISITOR_REGISTRATION',
    },
  },
};


module.exports = tracks;
