const React = require('react');
const PropTypes = require('prop-types');

const Header = ({
  title: titleCollection,
  subtitle: subtitleCollection,
}) => {
  const title = titleCollection && (
    <p className="collection-box-item__title font-secondary-text">{titleCollection}</p>
  );
  const subtitle = subtitleCollection && (
    <p className="collection-box-item__title font-secondary-text">{subtitleCollection}</p>
  );

  return (
    <>
      {title}
      {subtitle}
    </>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

module.exports = Header;
