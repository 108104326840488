const React = require('react');

const SecondaryBanner = require('@mshops-components-library/secondary-banner').default;

const { useRenderContext } = require('../../pages/home/context');

const BannerContainer = (props) => {
  const { deviceType, appearance: { appearance } } = useRenderContext();
  const { properties: { style_config: stylesConfig } } = appearance;
  const {
    primary_color: primaryColor,
    secundary_color: secondaryColor,
    theme,
    light_text_color: lightTextColor,
    color_text: colorText,
    text_secundary_button_color: textSecondaryButtonColor,
    fonts,
  } = stylesConfig;
  const { css_font_family: primaryFont } = fonts.find(({ priority }) => priority === 'Primaria');
  const { css_font_family: secondaryFont } = fonts.find(({ priority }) => priority === 'Secundaria');
  const themeStyles = {
    primaryColor,
    secondaryColor,
    theme,
    lightTextColor,
    colorText,
    textSecondaryButtonColor,
    primaryFont,
    secondaryFont,
  };
  return <SecondaryBanner {...props} deviceType={deviceType} themeStyles={themeStyles} />;
};

module.exports = BannerContainer;
