const React = require('react');
const PropTypes = require('prop-types');

const { ProgressIndicatorCircular } = require('@andes/progress-indicator-circular');
const { useRenderContext } = require('../../pages/home/context');


function Layout({ children }) {
  const { fetching } = useRenderContext();
  return (
    <div>
      { fetching
      && <ProgressIndicatorCircular
        modifier="fullscreen"
        size="xlarge"
      /> }
      {children}
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

Layout.defaultProps = {
  fetching: false,
};

module.exports = Layout;
