const types = require('../Editable/types');

const THEME_TITLE_FONT = 'eshops';

const getCollectionBoxesProps = (props) => {
  const {
    componentId,
    id,
    boxes,
    behavior,
  } = props;

  return {
    type: types.COLLECTION_BOXES,
    payload: {
      componentId,
      id,
      boxes,
      behavior,
    },
  };
};

module.exports = { getCollectionBoxesProps, THEME_TITLE_FONT };
