const React = require('react');

const { useRenderContext } = require('../pages/home/context');
const metadataPropType = require('../components/metadataProvider/metadataPropType');

const withMetadata = (Base) => {
  const ComponentBase = (props, { metadata }) => {
    const { device, isEshops } = useRenderContext();
    const { layoutTheme, lowEnd } = metadata;

    return (
      <Base
        layoutTheme={layoutTheme}
        deviceType={device}
        lowEnd={lowEnd}
        isEshops={isEshops}
        {...props}
      />
    );
  };

  ComponentBase.contextTypes = {
    metadata: metadataPropType.isRequired,
  };

  return ComponentBase;
};

module.exports = withMetadata;
