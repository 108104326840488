const React = require('react');

const SecondaryBanner = require('@mshops-components-library/secondary-banner').default;

const { useRenderContext } = require('../../pages/home/context');

const BannerContainer = (props) => {
  const { device } = useRenderContext();

  return <SecondaryBanner {...props} deviceType={device} />;
};

module.exports = BannerContainer;
